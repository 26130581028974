const dev = {
	// API_ENDPOINT_URL: 'http://192.168.0.237:2000/api'
	// API_ENDPOINT_URL: 'https://sale-crm.crustycodes.com/backend/public/api'
	// API_ENDPOINT_URL: 'https://sale-crm.klues.co.in/backend/public/api'
	API_ENDPOINT_URL:'https://novasync-salecrm-absax.airi-sense.co.in/backend/public/api'
  };
  
  const prod = {
	// API_ENDPOINT_URL: 'http://192.168.0.237:2000/api'
	// API_ENDPOINT_URL: 'https://sale-crm.crustycodes.com/backend/public/api'
	// API_ENDPOINT_URL: 'https://sale-crm.klues.co.in/backend/public/api'
	API_ENDPOINT_URL:'https://novasync-salecrm-absax.airi-sense.co.in/backend/public/api'
  };
  
  const test = {
	// API_ENDPOINT_URL: 'http://192.168.0.237:2000/api'
	// API_ENDPOINT_URL: 'https://sale-crm.crustycodes.com/backend/public/api'
	// API_ENDPOINT_URL: 'https://sale-crm.klues.co.in/backend/public/api'
	API_ENDPOINT_URL:'https://novasync-salecrm-absax.airi-sense.co.in/backend/public/api'
  };
  
  const getEnv = () => {
	  switch (process.env.NODE_ENV) {
		  case 'development':
			  return dev
		  case 'production':
			  return prod
		  case 'test':
			  return test
		  default:
			  break;
	  }
  }
  
  export const env = getEnv()
  
